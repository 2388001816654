import type { ReactNode } from 'react'
import type { State } from '../build/state'
import type { ServiceId } from 'shared/constants'

export type Maybe<T> = T | null | undefined

export type Tag = { id: string; name: string }

export const enum PreviewImageShape {
  Rect = 'rect',
  Circle = 'circle',
  Square = 'square'
}

export type Image = {
  id: string
  url: string
  alternativeText?: Maybe<string>
}

export type Article = {
  id: string
  title: string
  pageId: string
  tags: Tag[]
  nodes: ArticleNode[]
  previewImageShape: PreviewImageShape
  previewImage: Image
  readingTime: number
  authors: Employee[]
  createdAt: string
}

export type Employee = {
  id: string
  fullName: string
  occupation: string
  avatar: Image
  pinkAvatar: Image
  socials: {
    linkedin?: string
    github?: string
  }
}

type ComplexNode<
  T extends string,
  C extends Record<string, unknown>,
  E extends Record<string, unknown> = Record<string, unknown>
> = {
  type: T
  children: C[]
} & E

export type TextNode = {
  text: string
  type: 'text'
  bold?: true
  italic?: true
}

export type LinkNode = ComplexNode<'link', TextNode, { url: string }>
export type ParagraphNode = ComplexNode<'paragraph', TextNode | LinkNode>
export type HeadingNode = ComplexNode<'heading', TextNode, { level: 1 | 2 | 3 | 4 | 5 | 6 }>
export type ListItemNode = ComplexNode<'list-item', TextNode>
export type ListNode = ComplexNode<'list', ListItemNode, { format: 'ordered' | 'unordered' }>
export type ImageNode = ComplexNode<'image', TextNode, { image: Image }>

export type ArticleNode = ParagraphNode | HeadingNode | ListNode | ImageNode

export type Store = State

export const enum Category {
  B2C = 'B2C',
  B2B = 'B2B',
  RnD = 'R&D',
  Research = 'Research',
  Python = 'Python',
  Django = 'Django',
  Saas = 'SaaS',
  Enterprise = 'Enterprise',
  React = 'React',
  Angular = 'Angular.js',
  Vue = 'Vue',
  Rails = 'Rails',
  RubyOnRails = 'Ruby on Rails',
  Mobile = 'Mobile',
  Ecommerce = 'eCommerce',
  AdTech = 'AdTech'
}

export const enum Service {
  Frontend = 'Frontend',
  Backend = 'Backend',
  ML = 'Machine Learning',
  Design = 'Design',
  FullCycle = 'Full Cycle',
  ItAudit = 'IT Audit'
}

export type Case = {
  id: string
  title: string
  about: string
  image: ReactNode
  squareImage?: ReactNode
  categories: Array<Category>
  services: Array<ServiceId>
  client: string
  year: string
  websiteUrl: string | null
}
